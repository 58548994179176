"use client"

import React, { useState, useEffect } from "react";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import './scroll.css';


const ScrollToTop = () => {
  const isBrowser = () => typeof window !== "undefined";
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 90) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function scrollToTop() {
    if (!isBrowser()) return;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div>
      <button className={`fixed bottom-8 right-4 p-2 mr-6 mb-[71px] z-50 items-center text-xs flex gap-2 scrollToTopButton ${isVisible ? 'visible' : ''}`} onClick={scrollToTop} id="text">
        <FaRegArrowAltCircleUp className="inline-block h-8 w-8 bg-green-500 text-black rounded-full" />
      </button>
    </div>
  );
};

export default ScrollToTop;

{
  /* <div>
        <Link href="/">
            <FaRegArrowAltCircleUp className='w-10 h-10 text-black fixed bottom-6 right-9 z-[100] bg-orange-400 shadow-2xl rounded-full p-3'/>
        </Link>
    </div> */
}
